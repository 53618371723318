import { useEffect, useRef, useState } from 'react'
import { decode } from 'jsonwebtoken'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { parseQuery } from '~core/util/parseQuery'
import { buildQuery } from '~core/util/buildQuery'
import { usePost } from '~brix/connectors/fetch'
import { exconEventId } from '../state/exconEventId'
import { tokenParticipantId } from '../state/tokenParticipantId'
import { useExconSettingsContext } from '~brix/connectors/context'
import { config } from '~config'

export const useParticipantAuthentication = () => {
  const settings = useExconSettingsContext()
  const { replace } = useHistory()
  const { search, pathname } = useLocation()
  const { exconId } = useParams<{ exconId: string }>()

  const [done, setDone] = useState(false)

  const { token, ...other } = parseQuery(search)
  const redirectPath = buildQuery(pathname, other)

  const [eventId, setEventId] = useRecoilState(exconEventId)
  const setParticipantId = useSetRecoilState(tokenParticipantId)

  const { post, isPosting } = usePost({ initialFetching: Boolean(token) })
  const loggingIn = useRef(false)

  useEffect(() => {
    const login = async () => {
      await post('/auth/participant/tokenHandShake', { token, exconId })
      // Extract eventId if it is there
      const claims = decode(token) as Record<string, string>

      const _eventId = claims?.eventId ?? null
      const _participantId = claims?.participantId ?? null

      setEventId(_eventId)
      setParticipantId(_participantId)

      setDone(true)

      replace(redirectPath)
    }

    const hostname = settings.get('hostname')
    const secureHosts = [
      'localhost',
      config.firebase.exconPlaceHost,
      'https://shell-chat-event-app-stg.42chat.com',
      'https://shell-chat-event-app.42chat.com',
    ]

    if ((!secureHosts.includes(hostname) && token) || (token && !loggingIn.current)) {
      loggingIn.current = true
      login()
    }
  }, [exconId, token, redirectPath, post, replace, setEventId, setParticipantId, settings])

  return { isPosting: isPosting && !done, eventId }
}
