import React from 'react'
import { useRouteMatch, useHistory, Switch, Route } from 'react-router-dom'
import { combineUrl } from '~core/util/string/combineUrl'
import { LogoProgress, withErrorBoundary } from '~controls'
import ExconSettingProvider from '~brix/connectors/context/ExconSettingsProvider'
import UnauthorizedHandlerProvider from '../_context/UnauthorizedHandlerProvider'
import AppVersionCheck from '../version/AppVersionCheck'
import { useExconSettings } from './hooks/useExconSettings'
import Authorization from './common/Authorization'
import Redirector from './Redirector'
import Routes from './Routes'

import { ExconBrandingProvider } from './common/ExconBrandingProvider'
import { ExconTopBar, ExconFooter } from '~layout'
import { Flex } from 'mui-blox'
import ExconTitleProvider from '../_layout/exconFrame/ExconTitleProvider'

const ExconRoot = () => {
  const { push } = useHistory()

  const {
    url,
    path,
    params: { exconId },
  } = useRouteMatch('/excon/:exconId')

  const { isLoading: isLoadingSettings, data: settings } = useExconSettings()
  // TODO: replace all usages of useExconSettingsContext

  const unauthorizedHandler = () => {
    // TODO: participantAuth remove after 1 week
    localStorage.removeItem('participantAuth')

    push(combineUrl(url, 'authFailure'))
  }

  return (
    <UnauthorizedHandlerProvider value={unauthorizedHandler}>
      <ExconSettingProvider exconId={exconId} Fallback={LogoProgress}>
        <Authorization isWaiting={isLoadingSettings}>
          <ExconTitleProvider>
            <ExconBrandingProvider>
              <Flex flexColumn>
                <ExconTopBar settings={settings} />
                {/* <AuthTokenRedirect /> */}
                <Switch>
                  <Route path={combineUrl(path, 'redirect')} component={Redirector} />
                  <Route path={path} component={Routes} />
                </Switch>
                <ExconFooter settings={settings} />
              </Flex>
            </ExconBrandingProvider>
          </ExconTitleProvider>
        </Authorization>
      </ExconSettingProvider>
      <AppVersionCheck />
    </UnauthorizedHandlerProvider>
  )
}

ExconRoot.propTypes = {
  // Add PropTypes for props passed from parent components here.
}

export default withErrorBoundary()(ExconRoot)
